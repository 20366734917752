const scrollers = document.querySelectorAll(".js-click-scroller");

scrollers.forEach((scroller) => {
	scroller.addEventListener("click", () => {
		const header = document.querySelector(".js-header-fixed");
		const parent = scroller.closest(".js-scroller-parent");

		const { bottom } = parent.getBoundingClientRect();

		const distance =
			bottom > 1
				? bottom +
				  document.documentElement.scrollTop -
				  header.clientHeight
				: 0;

		window.scrollTo({
			top: distance,
			behavior: "smooth",
		});
	});
});
