const popups = document.querySelectorAll(".js-read-more-popup");

[...popups].map((popup) => {
	const modal = popup.querySelector(".js-read-more-popup-modal");
	const toggler = popup.querySelector(".js-read-more-popup-toggle");

	const toggle = (e) => {
		e.preventDefault();

		if (!e.target.closest(".js-read-more-popup-content")) {
			modal.classList.toggle("visible");
		}
	};

	toggler.addEventListener("click", toggle);
	modal.addEventListener("click", toggle);
});
