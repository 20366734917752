const fixHeader = () => {
	const header = document.querySelector(".js-header-fixed");

	if (!header) return;

	header.parentElement.style.paddingTop = `${header.offsetHeight}px`;

	if (document.querySelector(".admin-bar")) {
		header.style.top = "32px";
	}
};

fixHeader();

window.addEventListener("resize", fixHeader);
