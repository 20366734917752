import Splide from "@splidejs/splide";

const sliders = document.querySelectorAll(".js-slider");

Array.from(sliders).map((sliderWrap) => {
	const slider = sliderWrap.querySelector(".js-slider-main");

	new Splide(slider, {
		pagination: false,
		rewind: true,
		autoHeight: true,
	}).mount();
});
