import Player from "@vimeo/player";

const pageHeaders = document.querySelectorAll(".js-page-header--video");

function matchHeight({ elementToChange, referenceElement }) {
	const { height } = referenceElement.getBoundingClientRect();

	elementToChange.style.height = `${parseInt(height) + 1}px`;
}

function setupPageHeader(header) {
	const iframe = header.querySelector(".js-vimeo-video");

	matchHeight({ elementToChange: header, referenceElement: iframe });

	const mq = window.matchMedia("(min-width: 769px)");

	if (mq.matches) {
		header.addEventListener("mouseover", onMouse);
		header.addEventListener("mouseout", onMouse);

		function onMouse(e) {
			e.type === "mouseout"
				? header.classList.remove("highlight-video-hover")
				: header.classList.add("highlight-video-hover");
		}

		function onClick(e) {
			if (e.target.classList.contains("c-btn")) {
				return;
			}

			const player = new Player(iframe);

			player.on('play', data => {
				header.classList.add("is-playing");
			});

			player.on('ended', data => {
				header.classList.remove("is-playing");
			});

			player.play();

			header.classList.add("is-playing", "is-started");
			header.style.cursor = "initial";

			header.removeEventListener("mouseover", onMouse);
			header.removeEventListener("mouseout", onMouse);
			header.removeEventListener("click", onClick);
		}

		header.addEventListener("click", onClick);
	}
}

pageHeaders.forEach((header) => {
	document.addEventListener("DOMContentLoaded", () =>
		setupPageHeader(header)
	);
	window.addEventListener("resize", () => setupPageHeader(header));
});
