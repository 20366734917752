document.addEventListener("DOMContentLoaded", function () {
	const heightsToMatch = Array.from(
		document.querySelectorAll(".js-heading-match-height")
	);

	if (!heightsToMatch.length || !heightsToMatch[0].offsetHeight) return;

	const tallest = heightsToMatch.reduce(
		(a, b) => (a > b.offsetHeight ? a : b.offsetHeight),
		heightsToMatch[0].offsetHeight
	);

	heightsToMatch.forEach((item) => (item.style.minHeight = `${tallest}px`));
});
