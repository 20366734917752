import { toFormUrlEncoded } from "../utils";

class PostPaginator {
	constructor(buttons, contentContainer) {
		this.buttons = buttons;
		this.contentContainer = contentContainer;

		this.paginationData = {
			currentPage: 1,
			maxPages: 1,
			limit: 3,
		};

		this.updating = false;

		window.addEventListener("DOMContentLoaded", () => this.setup());
	}

	setup() {
		this.setPaginationData();
		this.setButtonVisibility();
		this.addListeners();
	}

	addListeners() {
		this.buttons.forEach((button) =>
			button.addEventListener("click", (e) => this.onClick(e))
		);
	}

	onClick(e) {
		e.preventDefault();

		if (this.updating) return;

		this.startUpdate();

		const action = e.currentTarget.dataset.type;
		const { currentPage: page } = this.paginationData;

		this.setPaginationData({
			currentPage: action === "prev" ? page + 1 : page - 1,
		});

		this.requestData();
	}

	setPaginationData(data = {}) {
		const nextData = Object.keys(data).length
			? data
			: this.contentContainer.dataset;

		this.paginationData = { ...this.paginationData, ...nextData };

		const { currentPage, maxPages, limit } = this.paginationData;

		this.paginationData.currentPage = parseInt(currentPage);
		this.paginationData.maxPages = parseInt(maxPages);
		this.paginationData.limit = parseInt(limit);
	}

	startUpdate() {
		this.updating = true;
	}

	endUpdate() {
		this.updating = false;
	}

	requestData() {
		const { postType, currentPage, limit } = this.paginationData;

		const body = {
			action: "loadmore",
			postType,
			page: currentPage,
			limit,
		};

		fetch(siteData.ajaxUrl, {
			method: "POST",
			headers: {
				"Content-Type":
					"application/x-www-form-urlencoded; charset=utf-8",
			},
			body: toFormUrlEncoded(body),
		})
			.then((res) => res.text())
			.then((data) => {
				this.onDataReceived(data);
			});
	}

	onDataReceived(data) {
		this.contentContainer.innerHTML = data;

		this.setButtonVisibility();
		this.endUpdate();
	}

	setButtonVisibility() {
		const { currentPage, maxPages } = this.paginationData;

		const hide = {
			prev: currentPage == maxPages,
			next: currentPage == 1,
		};

		if (maxPages == 1) {
			hide.prev = true;
			hide.next = true;
		}

		this.buttons.forEach((button) => {
			const { type } = button.dataset;
			button.style.display = hide[type] ? "none" : "block";
		});
	}
}

const buttons = document.querySelectorAll(".js-load-more");
const container = document.querySelector(".js-ajax-news-content");

if (buttons.length && container) {
	new PostPaginator(buttons, container);
}
