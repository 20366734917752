const buttons = document.querySelectorAll(
	".js-cta-popup-btn, .js-cta-popup-close-btn"
);

const togglePopup = (e) => {
	e.preventDefault();

	const container = e.currentTarget.closest(".js-cta");
	const popup = container.querySelector(".js-cta-popup");

	popup.classList.toggle("d-none");
};

buttons.forEach((btn) => {
	btn.addEventListener("click", togglePopup);
});
