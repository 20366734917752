import Tabby from "tabbyjs";
import "../../../node_modules/tabbyjs/src/sass/tabby-ui-vertical.scss";

document.addEventListener("DOMContentLoaded", function () {
	const tabs = document.querySelector("[data-tabs]");

	if (!tabs) return;

	const tabby = new Tabby("[data-tabs]");
	tabby.setup();
});
