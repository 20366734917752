const toggleMenuVisibility = () =>
	document.querySelector(".js-mobile-menu-wrap").classList.toggle("visible");

const onClickMobileMenuLink = (e) => {
	const subMenu = e.currentTarget.parentElement.querySelector(
		".js-mobile-menu__sub-menu, .js-mobile-menu__sub-menu__sub-menu"
	);

	const subMenuNoParentLink = e.currentTarget.href.slice(-1) === "#";
	const subMenuLinkClickToggle =
		subMenu && e.target.classList.contains("js-toggler");

	if (subMenuLinkClickToggle || subMenuNoParentLink) {
		e.preventDefault();
		subMenu.classList.toggle("visible");
	} else {
		toggleMenuVisibility();
	}
};

document
	.querySelectorAll(".js-mobile-menu__link, .js-mobile-menu__sub-menu__link")
	.forEach((link) => link.addEventListener("click", onClickMobileMenuLink));

document
	.querySelectorAll(".js-mobile-menu-visibility-btn")
	.forEach((btn) => btn.addEventListener("click", toggleMenuVisibility));

const resetXPosition = (el) => {
	el.style.left = null;
	el.style.right = null;
};

const onHoverEnsureSubMenuRemainsVisible = (e) => {
	const subMenu = e.currentTarget.querySelector("[data-level]");

	if (!subMenu) return;

	resetXPosition(subMenu);

	const {
		right: rightPos,
		width: subMenuWidth,
	} = subMenu.getBoundingClientRect();

	const isOffScreen = rightPos > window.innerWidth;
	const alignWithParent = subMenu.dataset.level == 1;

	if (isOffScreen) {
		subMenu.style.left = "initial";
		subMenu.style.right = alignWithParent ? 0 : `${subMenuWidth}px`;
	}
};

const onClickMenuLink = (e) => {
	const isEmptyAnchor = e.currentTarget.href.slice(-1) === "#";

	if (isEmptyAnchor) {
		e.preventDefault();
	}
};

document
	.querySelectorAll(".js-main-menu__item, .js-main-menu__sub-menu__item")
	.forEach((item) => {
		item.addEventListener("mouseover", onHoverEnsureSubMenuRemainsVisible);
	});

document
	.querySelectorAll(".js-main-menu__link, .js-main-menu__sub-menu__link")
	.forEach((item) => {
		item.addEventListener("click", onClickMenuLink);
	});
