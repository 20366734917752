import { Loader } from "google-maps";

const loadMap = (mapElement) => {
	const loader = new Loader(siteData.googleMapsAPIKey);

	const { lat, lng } = mapElement.dataset;

	if (!lat || !lng) {
		return;
	}

	const latLng = {
		lat: parseFloat(lat),
		lng: parseFloat(lng),
	};

	const google = loader.load().then((google) => {
		const map = new google.maps.Map(mapElement, {
			center: latLng,
			zoom: 15,
		});

		const marker = new google.maps.Marker({
			position: latLng,
			map,
			icon: "/app/themes/bootham/assets/icons/map-marker.png",
		});
	});
};

document.addEventListener("DOMContentLoaded", () => {
	const maps = document.querySelectorAll(".js-google-map");

	maps.forEach((map) => loadMap(map));
});
