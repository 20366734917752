import Splide from "@splidejs/splide";
import Video from "@splidejs/splide-extension-video";

const sliders = document.querySelectorAll(".js-video-slider");

Array.from(sliders).map((sliderWrap) => {
	const thumbnails = sliderWrap.querySelector(".js-video-slider-thumbnails");
	const slider = sliderWrap.querySelector(".js-video-slider-main");

	const secondarySlider = new Splide(thumbnails, {
		fixedWidth: 240,
		height: 180,
		gap: 10,
		cover: true,
		isNavigation: true,
		arrows: false,
		pagination: false,
		rewind: true,
		breakpoints: {
			796: {
				fixedWidth: 120,
				height: 90,
			},
		},
	}).mount();

	new Splide(slider, { pagination: false, rewind: true })
		.sync(secondarySlider)
		.mount({ Video });
});
