// TODO: Add event listener to prevent empty submission
const replyButtons = document.querySelectorAll(".js-post-reply-button");

const onReplyClick = (e) => {
	const btn = e.currentTarget;
	const container = btn.closest(".js-comment-container");
	const replyBox = container.querySelector(".js-reply-form-container");
	const inputCommentParent = container.querySelector("#comment_parent");

	inputCommentParent.value = btn.dataset.commentParentId;

	replyBox.classList.remove("d-none");

	const replyBoxPos =
		replyBox.getBoundingClientRect().top +
		document.documentElement.scrollTop -
		40;

	window.scrollTo({
		top: replyBoxPos,
		behavior: "smooth",
	});
};

replyButtons.forEach((button) =>
	button.addEventListener("click", onReplyClick)
);
