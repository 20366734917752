/**
 * Create a parallax effect for a given element.
 *
 * The element that is given will form the scene for the parallax effect.
 * The first child of the scene with the class provided in the options
 * will be the element that is animated.
 *
 *
 * Depth is how close or far aware the parallax element should be in
 * relation to the scene. Larger depth === a faster animation
 *
 * Note: your scene element should be positioned absolutely with overflow
 * hidden.
 */
class Parallax {
	constructor(
		element,
		options = { childClass: ".js-parallax__child", depth: 2.5 }
	) {
		this.element = element;
		this.child = this.element.querySelector(options.childClass);
		this.options = options;

		window.addEventListener("DOMContentLoaded", () => this.setupScene());
		window.addEventListener("resize", () => this.setupScene());
		document.addEventListener("scroll", () => this.onAnimate());
	}

	setupScene() {
		// Relative parent ensures getBoundingClientRect returns accurate height
		this.element.parentElement.style.position = "relative";

		const windowHeight = document.documentElement.clientHeight;
		const sceneHeight = this.element.getBoundingClientRect().height;

		// speed: larger child means more room to cover in the same timeline
		const depth = this.getDepth();
		const newChildHeight = Math.floor(sceneHeight * depth);

		// reference point is the bottom of the scene so the following
		// length will start the animation as the scene enters the viewport
		// and end it when the scene has been completely scrolled past
		const animationLength = sceneHeight + windowHeight;

		this.scene = {
			windowHeight,
			height: sceneHeight,
			child: {
				height: newChildHeight,
				translate: Math.floor(sceneHeight - newChildHeight),
			},
			animationLength,
		};

		this.child.style.height = `${this.scene.child.height}px`;
		this.child.style.width = "100%";

		// run incase parallax is visable on page load
		this.onAnimate();
	}

	onAnimate() {
		if (this.shouldAnimate()) this.animate();
	}

	shouldAnimate() {
		// catch usage before DOM fully loaded
		if (!this.scene) return false;

		const remaining = this.checkAndSetRemaining();
		const { animationLength } = this.scene;

		const started = animationLength - remaining > 0;
		const finished = remaining < 0;

		return started && !finished;
	}

	animate() {
		const translateAmount =
			this.scene.child.translate * this.percentRemaining();

		this.child.style.transform = `translateY(${translateAmount}px)`;
	}

	percentRemaining() {
		return (
			Math.floor((this.remaining / this.scene.animationLength) * 100) /
			100
		);
	}

	checkAndSetRemaining() {
		this.remaining = this.element.getBoundingClientRect().bottom;

		return this.remaining;
	}

	getDepth() {
		let dataDepth = parseFloat(this.element.dataset.depth);

		dataDepth = dataDepth < 1 ? 1 : dataDepth;

		return dataDepth || this.options.depth;
	}
}

document.querySelectorAll(".js-parallax").forEach((element) => {
	new Parallax(element);
});
